import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CompetitionBlock from "../components/MainStats/CompetitionBlock"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import SelectMenuNav from "../components/Utilities/SelectMenuNav"
import InfoBar from '../components/Utilities/InfoBar'


// query($slug: Date!) {

export const query = graphql`
  query($slug: String!) {
    allSheetMessiClubStats(filter: { year: { eq: $slug } }) {
      edges {
        node {
          year
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          xa
          fkc
          xgc
          xac
        }
      }
    }
    allSheetRonaldoClubStats(filter: { year: { eq: $slug } }) {
      edges {
        node {
          year
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          xa
          fkc
          xgc
          xac
        }
      }
    }
  }
`

function ClubStatsSeason(props) {

  const path = props.location.pathname;

  const mdata = props.data.allSheetMessiClubStats.edges
  const rdata = props.data.allSheetRonaldoClubStats.edges

  const year = mdata[0].node.year.replace(/-/g, '/')

  return (
    <Layout>
      <SEO
        title={`${year} Season Goals and Stats - Messi vs Ronaldo`}
        canonicalPath={path === "/club-stats/" ? '/club-stats/2024-2025/' : `${path}/`}
        description={path === '/club-stats/' ? (
          `The biggest debate in football - but who is the best, Messi or Ronaldo? We provide all the goals and stats to help you reach your own conclusion.`
        ) : path === '/club-stats/2024-2025/' ? (
          `Messi and Ronaldo stats this season. Who has scored more goals in ${year}? What about assists, free kicks and even match ratings?`
        ) : (
          `Who scored more goals in ${year}? Messi or Ronaldo? What about assists, free kicks and even match ratings? Find all of these ${year} stats and more on messivsronaldo.app!`
        )}
      />

      <h1 className="sr-only">{year} <span>Season Goals and Stats - Messi vs Ronaldo</span></h1>

      <SelectMenuNav
        current={mdata[0].node.year}
        parentPath="club-stats"
      />
      
      {mdata[0].node.year === '2023' || mdata[0].node.year === '2024' || mdata[0].node.year === '2025' || mdata[0].node.year === '2026' || mdata[0].node.year === '2027' ? (
        <div className="w-full p-4 max-w-screen-sm mx-auto mt-4">
          <InfoBar classes="w-full justify-center" msg={`Messi's ${mdata[0].node.year} club season stats and Ronaldo's stats in the same period`} />
        </div>
      ) : ''}

      {mdata[0].node.year == '2023-2024' || mdata[0].node.year == '2024-2025' || mdata[0].node.year == '2025-2026' || mdata[0].node.year == '2026-2027' ? (
        <div className="w-full p-4 max-w-screen-sm mx-auto mt-4">
          <InfoBar classes="w-full justify-center" msg={`Ronaldo's ${mdata[0].node.year} club season stats and Messi's stats in the same period`} />
        </div>
      ) : ''}

      <CompetitionBlock competition="All Competitions" type="club" mdata={mdata} rdata={rdata} subtitle="Excluding country" perfmsg="[comps] only" smallPrint={year === '2021/2022' ? 'Ronaldo stats include 1 appearance in Serie A for Juventus' : year === '2022/2023' ? 'Ronaldo stats include 3 goals and 2 assists in 16 appearances for Man Utd' : ''} />

      <CompetitionBlock competition="League" type="club" mdata={mdata} rdata={rdata}  smallPrint={year === '2021/2022' ? 'Ronaldo stats include 1 appearance in Serie A for Juventus' : year === '2022/2023' ? 'Ronaldo stats include 1 goal in 10 appearances for Man Utd' : ''} />

      {mdata.filter(d => d.node.competition === "MLS Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "MLS Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="MLS Cup" type="club" subtitle="MLS Cup Playoffs" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Champions League").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Champions League").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Champions League" compTitle="UEFA Champions League" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Champions League Qualifying").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Champions League Qualifying").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Champions League Qualifying" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Continental Tournament").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Continental Tournament").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Continental Tournament" subtitle="AFC Champions League / CONCACAF Champions Cup" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Europa League").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Europa League").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Europa League" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "UEFA Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "UEFA Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="UEFA Cup" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {/* <CompetitionBlock competition="Other Cups" subtitle="Domestic cups, UEFA Super Cup, Club World Cup" type="club" mdata={mdata} rdata={rdata} /> */}

      {mdata.filter(d => d.node.competition === "Domestic Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Domestic Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Domestic Cup" type="club" subtitle="Copa del Rey, Coppa Italia, FA Cup, Coupe de France, Saudi King Cup, US Open Cup" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "League Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "League Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="League Cup" type="club" subtitle="English Football League Cup" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Leagues Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Leagues Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Leagues Cup" type="club" subtitle="USA/Mexican Cup Competition" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Arab Club Champions Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Arab Club Champions Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Arab Club Champions Cup" type="club" subtitle="Cup Competition with teams from Arab states" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Domestic Super Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Domestic Super Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Domestic Super Cup" type="club" subtitle="Supercopa de Espana, Supercoppa Italiana, FA Community Shield, Trophée des Champions, Saudi Super Cup, Campeones Cup" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "UEFA Super Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "UEFA Super Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="UEFA Super Cup" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Club World Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Club World Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Club World Cup" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Club and Country").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Club and Country").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Club and Country" type="both" mdata={mdata} rdata={rdata} />
      ) : ''}
      

      <LastUpdated type="Club Stats" />
      
    </Layout>
  )
}

export default ClubStatsSeason
