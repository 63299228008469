import React from "react"
import Icon from "./Icon"


class InfoBar extends React.Component {

  render() {

    return (

      <div className={`px-4 py-2 bg-noir-lighten10 inline-flex items-center text-sm text-gray-600 ${this.props.classes}`}>
        <p>
          <Icon iconName="icon-info-circle" class="mr-2 flex-none" />
          <span>{this.props.msg || 'All stats on this page will auto-update'}</span>
        </p>
      </div>

    )
  }
}

export default InfoBar