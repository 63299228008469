import { Link } from "gatsby"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { navigate } from 'gatsby'

class SelectMenuNav extends React.Component {

  onChangeFunc = (e) => {
    navigate(`/${this.props.parentPath}/${e.target.value}`);
  }

  render () {
  
    return (

      <div className={`w-full flex justify-center`}>

        <select className={`cursor-pointer text-noir px-5 py-3 pr-16 text-base rounded-full font-semibold appearance-none hover:bg-gray-200 focus:bg-gray-200 focus:outline-none xl:mb-4`} onChange={this.onChangeFunc} defaultValue={`${this.props.current}/`}>

          {this.props.parentPath === 'international-stats' ? (
            <option value="">All Time</option>
          ) : ''}

          {this.props.site.allSitePage.edges
          .filter(link => (link.node.path.includes(this.props.parentPath)))
          .filter(link => link.node.path !== '/club-stats/')
          .filter(link => link.node.path !== '/club-stats/messi-vs-ronaldo-in-spain/')
          .filter(link => link.node.path !== '/club-stats/champions-league/')
          .filter(link => link.node.path !== '/club-stats/el-clasico/')
          .filter(link => link.node.path.includes('/detailed-stats/') === false)
          .filter(link => link.node.path.includes('/match-histories/') === false)
          .filter(link => link.node.path.includes('/all-time-stats/') === false)
          .map(link => (
            <option key={link.node.path} value={`${link.node.context.slug}/`}>

              {this.props.parentPath === "club-stats" ? link.node.context.slug.replace(/-/g, '/')
              : link.node.context.slug }

            </option>
          ))}

        </select>

      </div>

    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query SelectNavQuery {
        allSitePage {
          edges {
            node {
              path
              context {
                slug
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <SelectMenuNav site={data} {...props} />
    )}
  />
)

